import React, { useEffect, useState } from "react";
import bg from "../../../assets/LandingPageBg.webp";
import mobile from "../../../assets/mobile.png";
import group2 from "../../../assets/mm33.png";
import Aos from "aos";
import "aos/dist/aos.css";
import styles from "./LandingPage.module.css";
import cx from "classnames";
// import videoo from "../../../assets/videoo.mp4";
import data from "../../../assets/truckrrjson.json";
import googlestore from "../../../assets/googlestore.png";
import appstore from "../../../assets/appstore.png";

import Lottie from "react-lottie";

export default function LandingPage() {
  const [showMob, setMob] = useState(false);
  const [showMobb, setMobb] = useState(false);
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: data,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);
  useEffect(() => {
    setTimeout(function () {
      setMob(true);
    }, 1000);
  }, []);
  // useEffect(() => {
  //   setTimeout(function () {
  //     setMobb(true);
  //   }, 3100);
  // }, []);
  return (
    <div className={styles.bckdivmaindiv} id="top">
      <div className={styles.bckdiv}>
        <img src={bg} alt="truckae" />
      </div>
      <div className={styles.textandbckgrd}>
        <div className={styles.textandbckgrddiv}>
          <h1 data-aos-duration="700">Making logistics easier</h1>
          <h1 data-aos-duration="700">and hassle free</h1>
          <div className={styles.textpbacjgrd}>
            <p data-aos-duration="1000">
              UAE's most efficient on demand truck provider
            </p>
          </div>
          {/* <div className={styles.appLinks}>
            <a href="#" target="_blank" className={styles.googlestoreImg}>
              <img src={googlestore} alt="googlestore" />
            </a>
            <a href="#" target="_blank" className={styles.appstoreImg}>
              <img src={appstore} alt="appstore" />
            </a>
          </div> */}
        </div>
        <div className={styles.textandbckgrdimgdiv}>
          <Lottie
            options={defaultOptions}
            width={800}
            loop={false}
            style={{ color: "red" }}
            className={cx(
              `${styles.mobimg} ${
                showMob ? [styles.mobimg2] : [styles.mobimg1]
              }`
            )}
          />
          <span className={styles.shadow}></span>
        </div>

        <div className={styles.textandbckgrdimgdiv2}>
          <Lottie
            options={defaultOptions}
            loop={false}
            style={{ color: "red" }}
            className={cx(
              `${styles.mobimg2} ${
                showMob ? [styles.mobimg2] : [styles.mobimg1]
              }`
            )}
          />
          <span className={styles.shadow}></span>
        </div>
      </div>
    </div>
  );
}
