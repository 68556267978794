import React from "react";
import NavBar from "../NavBar/NavBar";
import Footer from "../Footer/Footer";
import styles from "./terms.module.css";

export default function CancellationRefundPolicy() {
  return (
    <div>
      <NavBar />
      <div className={styles.privacypolicy}>
        <div className={styles.privacypolicy1}>
          <div className={styles.privacypolicyh2div}>
            <h2>Cancellation and Refund Policy</h2>
          </div>
          <div className={styles.privacypolicyh2divp}>
            <p>
                <strong>Cancellations</strong><br />
            All cancellations made five (5) minutes after driver allocation will incur a 
            cancellation fee. The cancellation of four (4) bookings in one day, after driver 
            allocation, will temporarily suspend a Customer's account for twenty-four (24) hours.

            <br />
            <br />
            <strong>Refunds</strong><br />
            During online payment, if amount is deducted from your account and payment fails, your account will be refunded automatically, within 7 working days.
            <br />
            Please note that the refund for online payments will be made into the online account that has been used for the transaction.

            <br /><br />

            If you have any other grievance/complain regarding your order, please reach out to our 
            Customer Care on <strong>support@truckae.com</strong> or on <strong>+918639701448</strong> and we will resolve this issue at 
            the earliest.
            </p>

          </div>

        </div>
      </div>
      <Footer />
    </div>
  );
}
