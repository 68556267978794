import React, { useState } from "react";
import styles from "./DriverPartnerbtns.module.css";
import circlel from "../../../images/circlel.png";

import driver from "../../../assets/driver.png";
import handshk from "../../../assets/handshk.png";

export default function DriverPartnerbtns(props) {
  return (
    <div>
      <div className={styles.driverbtns}>
        <div className={styles.driverbtnsimgdiv}>
          <div className={styles.driverbtnsimgdivimg}>
            <img src={circlel} alt="circlel" />
          </div>
          <div className={styles.driverbtnsimgdiv2}>
            {/* <div
              className={styles.driverbtnsimgdiv2imgp}
              style={{ width: "25%" }}
              onClick={() => props.driverFn("driver")}
              id="uno"
            >
              <img src={driver} alt="handshk" />
              <p>Drivers</p>
            </div> */}

            <div
              className={styles.driverbtnsimgdiv2imgpp2}
              style={{ width: "25%" }}
              onClick={() => props.partnerFn("partner")}
              id="dos"
            >
              <img src={handshk} alt="handshk" />
              <p>Partners</p>
            </div>
            <div
              className={styles.driverbtnsimgdiv2imgp}
              style={{ width: "25%" }}
              onClick={() => props.driverFn("driver")}
              id="uno"
            >
              <img src={driver} alt="handshk" />
              <p>Drivers</p>
            </div>

            <div
              className={`${styles.dash} ${
                props.driverShow === "driver" ? styles.dash2 : styles.dash1
              }`}
            ></div>
          </div>
          {/* <div className={styles.dashmaindiv}>
            <div className={styles.dash}></div>
          </div> */}
        </div>
      </div>
    </div>
  );
}
