import React from "react";
import styles from "./ExploreTruck.module.css";
import explrtruck from "../../../assets/explrtruck1.png";
import WhyChooseexplore from "../WhyChooseexplore/WhyChooseexplore";
import PartnerReview from "../PartnerReview/PartnerReview";

export default function ExploreTruck(props) {
  return (
    <div className={styles.introtruckdivmainnnnn}>
      <div className={styles.introtruckdiv}>
        <div className={styles.introtruckdivimg}>
          <img src={explrtruck} alt="explrtruck" />
        </div>
        <div className={styles.introtruckdivimgcontent}>
          <div>
            <h2>
              Explore Corporate Solutions with <span>Truckae</span>{" "}
            </h2>
          </div>
          <div>
            <p>
              At Truckae we leverage technology and real-time information for
              effective
              <br /> and efficient logistics management.
            </p>
          </div>
          <div>
            <button onClick={props.handleOpenmat} className={styles.btn}>
              Sign Up
            </button>
          </div>
        </div>
      </div>
      <WhyChooseexplore />
      {/* <PartnerReview /> */}
    </div>
  );
}
