import React, { useEffect } from "react";
import styles from "./OurHeighlights.module.css";
import easyuse from "../../../images/easyuse.png";
import electriccar from "../../../images/electriccar.png";
import statisticss from "../../../images/statisticss.png";
import handshakea from "../../../images/handshakea.png";
import Aos from "aos";
import "aos/dist/aos.css";

export default function OurHeighlights() {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);
  return (
    <div className={styles.OurHeighlightsmaindiv}>
      <div className={styles.OurHeighlightsmaindiv1}>
        <div className={styles.OurHeighlightsmaindivh2}>
          <h2>Our Highlights</h2>
        </div>
        <div
          data-aos="fade-down"
          className={styles.OurHeighlightsmaindivcontent}
        >
          <div>
            <img src={easyuse} alt="easyuse" />
            <p>Ease</p>
          </div>
          <div>
            <img src={electriccar} alt="electriccar" />
            <p>No emission</p>
          </div>
          <div>
            <img src={statisticss} alt="statisticss" />
            <p>Efficiency</p>
          </div>
          <div>
            <img src={handshakea} alt="handshakea" />
            <p>Reliability</p>
          </div>
        </div>
      </div>
    </div>
  );
}
