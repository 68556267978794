import React from "react";
import Styles from "./Footer.module.css";
import logo from "../../assets/footerlogo.png";
import rec8 from "../../images/rec8.png";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import CallIcon from "@mui/icons-material/Call";

// import logoimg from "../../Assets/footerimg.png";
import { Link, NavLink } from "react-router-dom";
import googlestore from "../../assets/googlestore.png";
import appstore from "../../assets/appstore.png";

const year = new Date().getFullYear();

export default function Footer() {
  const scroll = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  return (
    <div className={Styles.container}>
      <div className={Styles.containerone}>
        <div className={Styles.containerimg}>
          <img src={logo} alt="logo" />
          {/* <div className={Styles.appLinks}>
          <a href="#" target="_blank" className={Styles.googlestoreImg}>
            <img src={googlestore} alt="googlestore" />
          </a>
          <a href="#" target="_blank" className={Styles.appstoreImg}>
            <img src={appstore} alt="appstore" />
          </a>
        </div> */}
          <div className={Styles.address}>
            <p>
              <span>
                {" "}
                <LocationOnIcon sx={{ fontSize: "3rem", mr: 2 }} />
              </span>
              <span style={{ width: "300px" }}>
                Business Bay The Citadel Tower Office No: A9 , 20th floor ,
                Black swan Business Centre - Dubai - United Arab Emirates
              </span>
            </p>
            <p style={{ fontFamily: "inherit" }}>
              <CallIcon
                sx={{ fontSize: "3rem", fontFamily: "sans-serif", mr: 2 }}
              />
              +971 555399677
            </p>
          </div>
        </div>
        <div className={Styles.containerimg2}>
          <Link className="footertext" tag={Link} to="/fanda" onClick={scroll}>
            FAQs
          </Link>
          <Link
            className="footertext"
            tag={Link}
            to="/privacy-policy"
            onClick={scroll}
          >
            Privacy Policy
          </Link>
          <Link
            className="footertext"
            tag={Link}
            to="/terms-of-service"
            onClick={scroll}
          >
            Terms of Service
          </Link>
          {/* <Link className="footertext" tag={Link} to="/partner-terms-of-service">
          Partner's Terms of Service
        </Link> */}
          <Link
            className="footertext"
            tag={Link}
            to="/cancellation-policy"
            onClick={scroll}
          >
            Cancellation Policy
          </Link>
          {/* <a className="footertext" href="#">
          Terms and Conditions
        </a>
        <a className="footertext" href="#">
          Contact us
        </a> */}
          <p className={Styles.copyright}>
            © {year} All rights reserved Tekkr Truckae Transport L.L.C
          </p>
        </div>
        <div className={Styles.containerimg2}>
          <img src={rec8} alt="rec8" />
        </div>
      </div>
      {/* <div>
        <p className={Styles.copyright}>
          © {year} All rights reserved Tekkr Pvt. Ltd.
        </p>
      </div> */}
    </div>
  );
}
