import React, { useState } from "react";
import NavBar from "../../NavBar/NavBar";
import Footer from "../../Footer/Footer";
import AboutusMain from "../AboutusMain/AboutusMain";
import OurHeighlights from "../OurHeighlights/OurHeighlights";
import styles from "./Aboutus.module.css"
import bigdonut from "../../../assets/bigdonut.png"
import smalldonut from "../../../assets/smalldonut.png";

export default function Aboutus() {
  return (
    <div className={styles.aboutuspage}>
      <img src={bigdonut} alt="bigdonut" className={styles.bigdonut} />
      <img src={smalldonut} alt="smalldonut" className={styles.smalldonut} />
      <NavBar
        style={{
          position: "fixed",
          zIndex: "5",
          width: "100%",
        }}
        styles={{ color: "#000" }}
      />
      <AboutusMain />
      <OurHeighlights />
      <Footer />
    </div>
  );
}
