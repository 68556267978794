import React from "react";
import NavBar from "../NavBar/NavBar";
import Footer from "../Footer/Footer";
import styles from "./PrivacyPolicy.module.css";

export default function PrivacyPolicy() {
  return (
    <div>
      <NavBar />
      <div className={styles.privacypolicy}>
        <div className={styles.privacypolicy1}>
          <div className={styles.privacypolicyh2div}>
            <h2>Privacy policy</h2>
          </div>
          <div className={styles.privacypolicyh2divp}>
            <p>
              This privacy policy sets out how Truckae uses and protects any
              information that you give Truckae when you use this website/mobile
              application for Customers and Driver Partners. Your privacy
              matters to Tekkr Technologies Private Limited (the "Company",
              "we", "Truckae", or "us").
              <br />
              <br />
              This Privacy Policy ("Policy") describes the policies and
              procedures on the collection, use, disclosure and protection of
              your information when you use our website located at
              https://Truckae.ae/, or the TRUCKAE mobile applications named
              Truckae and Truckae Delivery Driver App (collectively, "Truckae
              Platform" or "Truckae App").
              <br />
              <br />
              The terms "you" and "your" refer to the user of the Truckae
              Platform. The term "Services" refers to any services offered by
              Truckae/You whether on the Truckae Platform or otherwise. This
              Policy is a part of and incorporated within, and is to be read
              along with, the applicable Terms of Service and the Driver Partner
              Terms and Conditions based on the user of Truckae.
              <br />
              <br />
              Truckae is committed to ensuring that your privacy is protected.
              Should we ask you to provide certain information by which you can
              be identified when using this website, then you can be assured
              that it will only be used in accordance with this privacy
              statement.
              <br />
              <br />
              By using the Truckae Platform and the Services, you agree and
              consent to the collection, transfer, use, storage, disclosure and
              sharing of your information as described and collected by us in
              accordance with this Policy. If you do not agree with the Policy,
              please do not use or access the Truckae Platform.
              <br />
              <br />
              Truckae may change this policy from time to time by updating this
              page. The current version of this policy is effective from 1st
              January, 2023. You should check this page from time to time to
              ensure that you are happy with any changes.
            </p>
          </div>
          <br />
          <div className={styles.privacypolicyh2divpart1div}>
            {/* //////////////point 1 ///////////////////////// */}

            <div className={styles.privacypolicyh2divpart1div22}>
              <div className={styles.privacypolicyh2divpart1divp1}>
                <h3>WHAT WE COLLECT</h3>
                <p>We may collect the following information:</p>
                <ul>
                  <li>
                    <p>Name and job title.</p>
                  </li>
                  <li>
                    <p>Contact information including email address.</p>
                  </li>
                  <li>
                    <p>
                      Demographic information such as postcode, address, mobile
                      number, login name, preferences and interests.
                    </p>
                  </li>
                  <li>
                    <p>
                      Use Our Services, we may collect and store information
                      about you to process your requests and automatically
                      complete forms for future transactions, including (but not
                      limited to) your phone number, address, email, billing
                      information, invoices, etc.
                    </p>
                  </li>
                  <li>
                    <p>
                      Other information relevant to customer surveys and/or
                      offers.
                    </p>
                  </li>
                  <li>
                    <p>
                      app install, uninstall and other installed apps
                      information.
                    </p>
                  </li>
                  <li>
                    <p>
                      If you sign up to use our Services as a Driver Partner, we
                      may collect location details, profile picture, copies of
                      government identification documents and other details
                      (KYC), vehicle related documents such as insurance,
                      pollution certificate, bank account details, utility
                      bills, registration certificate, driving license, etc. and
                      such other documents which evidence the health or fitness
                      of the vehicle to provide Services on the Truckae
                      Platform, call and SMS details which may be shared with
                      our customer while providing the Services
                    </p>
                  </li>
                  <li>
                    <p>
                      We collect background check and identity verification
                      information of the Driver Partner. This may include
                      information such as driver history or criminal record
                      (where permitted by law), and right to work. This
                      information may be collected by an authorized vendor on
                      the Company's behalf.
                    </p>
                  </li>
                  <li>
                    <p>
                      In case you sign up as a Driver Partner, enable features
                      that require Truckae's access to other applications on
                      Your Device aiming to prevent frauds towards the
                      customers. In case if the Driver Partner wishes to
                      participate in the referral program, the contact list
                      stored in the phone will be accessed by us post the Driver
                      Partner consents to share such selected mobile number. We
                      do not intend to share such selected mobile numbers with
                      any third party.
                    </p>
                  </li>
                </ul>
              </div>
            </div>
            <br />
            <div className={styles.privacypolicyh2divpart1div22}>
              <div className={styles.privacypolicyh2divpart1divp1}>
                <h3>WHAT WE DO WITH THE INFORMATION WE GATHER</h3>
                <p>
                  We require this information to understand your needs and
                  provide you with a better service, and in particular for the
                  following reasons:
                </p>
                <ul>
                  <li>
                    <div>
                      <h3>Internal Record Keeping</h3>
                      <p>
                        We may use the information to improve our products and
                        services.
                        <br />
                        <br />
                        We may periodically send promotional emails, SMS, app
                        based notifications and make voice calls about new
                        products, special offers or other information which we
                        think you may find interesting using the email address
                        and mobile number which you have provided.
                        <br />
                        <br />
                        From time to time, we may also use your information to
                        contact you for market research purposes. We may contact
                        you by email, SMS, voice, fax or mail. We may use the
                        information to customize the website according to your
                        interests.
                        <br />
                        <br />
                        You may specifically opt-out of the above. If you wish
                        to opt out of the above, please contact us at
                        +918639701448 or email us at support@truckae.com with
                        your request for opting out of such commercial
                        communication from us.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div>
                      <h3>Security</h3>
                      <p>
                        We are committed to ensuring that your information is
                        secure. In order to prevent unauthorized access or
                        disclosure we have put in place suitable physical,
                        electronic and managerial procedures to safeguard and
                        secure the information we collect online. We do not
                        retain any information collected from you for any longer
                        than is reasonably required by us for the purpose of our
                        services or such period as may be required by applicable
                        laws in the United Arab Emirates.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div>
                      <h3>Disclosure</h3>
                      <p>
                        We may disclose to third party services certain
                        personally identifiable information listed below:
                      </p>
                      <ul>
                        <li>
                          <p>
                            Information you provide us such as name, email,
                            mobile phone number.
                          </p>
                        </li>

                        <li>
                          <p>
                            o Information we collect as you access and use our
                            service, including device information, location and
                            network carrier. We collect precise or approximate
                            location data in foreground when the Truckae
                            Platform from the users' mobile devices if they
                            enable us to do so. Truckae collects this data from
                            the time a ride or delivery is requested until it is
                            finished, and any time the app is running in the
                            foreground of their mobile device. We use this data
                            to enhance your use of our apps, including to
                            improve pick-up locations, enable safety features,
                            and prevent and detect fraud. Truckae collects the
                            Driver Partner's location data, and links location
                            data collected during a trip with their customer's
                            account, even if the customer has not enabled us to
                            collect location data from their device. This
                            enables us to offer services to the customers like
                            receipt generation and customer support.
                          </p>
                        </li>
                      </ul>
                      <p>
                        This information is shared with third party service
                        providers so that we can:
                      </p>
                      <ul>
                        <li>
                          <p>Enhance your service experience.</p>
                        </li>

                        <li>
                          <p>
                            Personalize the app for you so you get the smoothest
                            experience.
                          </p>
                        </li>

                        <li>
                          <p>Perform behavioral analytics.</p>
                        </li>
                      </ul>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div className={styles.privacypolicyh2divpart1div22}>
              <div className={styles.privacypolicyh2divpart1divp1}>
                <h3>HOW WE USE COOKIES</h3>
                <p>
                  A cookie is a small file which asks permission to be placed on
                  your computer's hard drive. Once you agree, the file is added
                  and the cookie helps analyze web traffic or lets you know when
                  you visit a particular site. Cookies allow web applications to
                  respond to you as an individual. The web application can
                  tailor its operations to your needs, likes and dislikes by
                  gathering and remembering information about your preferences.
                  <br />
                  <br />
                  We use traffic log cookies to identify which pages are being
                  used. This helps us analyze data about webpage traffic and
                  improve our website in order to tailor it to customer needs.
                  We only use this information for statistical analysis purposes
                  and then the data is removed from the system.
                  <br />
                  <br />
                  Overall, cookies help us provide you with a better website
                  experience, by enabling us to monitor which pages you find
                  useful and which you do not. A cookie in no way gives us
                  access to your computer or any information about you, other
                  than the data you choose to share with us.
                  <br />
                  <br />
                  You can choose to accept or decline cookies. Most web browsers
                  automatically accept cookies, but you can usually modify your
                  browser setting to decline cookies if you prefer. This may
                  prevent you from taking full advantage of the website and/or
                  Truckae Platform.
                </p>
              </div>
            </div>

            <div className={styles.privacypolicyh2divpart1div22}>
              <div className={styles.privacypolicyh2divpart1divp1}>
                <h3>LINK TO OTHER WEBSITES</h3>
                <ul>
                  <li>
                    <p>
                      Our website may contain links to other websites of
                      interest. However, once you have used these links to leave
                      our site, you should note that we do not have any control
                      over that other website which may also be in the form of a
                      banner advertisement. We do not sell, share, rent or trade
                      the information we have collected about you, other than as
                      disclosed within this Privacy Policy or at the time you
                      provide your information. Therefore, we cannot be
                      responsible for the protection and privacy of any
                      information which you provide whilst visiting such sites
                      and such sites are not governed by this privacy statement.
                      You should exercise caution and look at the privacy
                      statement applicable to the website in question and may
                      contact that third party directly.
                    </p>
                  </li>

                  <li>
                    <p>
                      We will not disclose or share your personal information to
                      third parties unless we have your permission or are
                      required by law to do so. We may use your personal
                      information to send you promotional information about
                      third parties which we think you may find relevant or
                      interesting if you tell us that you wish this to happen.
                    </p>
                  </li>

                  <li>
                    <p>
                      You may request details of personal information which we
                      hold about you. If you would like a copy of the
                      information held on you please write to
                      support@truckae.com.
                    </p>
                  </li>

                  <li>
                    <p>
                      If you believe that any information we are holding on you
                      is incorrect or incomplete, please write to or email us as
                      soon as possible, at the above address. We will promptly
                      correct any information found to be incorrect. You can
                      also withdraw your consent by writing to us at
                      support@truckae.com. Such withdrawal will result in
                      discontinuance of the services for which such information
                      is required.
                    </p>
                  </li>
                </ul>
              </div>
            </div>

            <div className={styles.privacypolicyh2divpart1div22}>
              <div className={styles.privacypolicyh2divpart1divp1}>
                <h3>CONTACT US</h3>
                <p>
                  If there are any questions regarding this privacy policy or if
                  you wish to report a breach of the Privacy Policy, you may
                  contact us using the information on the support page. For any
                  queries or concerns regarding our Services, kindly email us at
                  support@truckae.com.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
