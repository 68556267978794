import React, { useState, useEffect } from "react";
import styles from "./NavBar.module.css";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";

import { useNavigate } from "react-router-dom";
import TruckaeLogo from "../../assets/TrkNewLogo.png";
import { Link } from "react-router-dom";
import cx from "classnames";

export default function NavBar(props) {
  const [isNavExpanded, setIsNavExpanded] = useState(false);

  const [isOpen, setIsOpen] = useState(false);
  const [token, setToken] = useState("");
  let navigate = useNavigate();

  const toggle = () => {
    setIsOpen(!isOpen);
  };
  const closeit = () => {
    setIsOpen(false);
  };

  // useEffect(() => {
  //   const t = get_cookie("token");
  //   setToken(t);
  // }, [token]);

  useEffect(() => {}, [token]);

  // const logout = () => {
  //   // axios.get(`${process.env.REACT_APP_BASE_URL}api/v1/register/`);
  //   set_cookie("token", "");
  //   window.location.reload(false);
  // };

  return (
    <>
      <nav
        className={cx(
          `${styles.navigation} ${
            isNavExpanded ? `` : [styles.navigationBorders]
          }`
        )}
      >
        <Link to="/" className={styles.brandName}>
          <img src={TruckaeLogo} alt="logo" className={styles.img} />
        </Link>
        <button
          className={styles.hamburger}
          onClick={() => {
            setIsNavExpanded(!isNavExpanded);
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5"
            viewBox="0 0 20 20"
            fill="white"
          >
            <path
              fillRule="evenodd"
              d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM9 15a1 1 0 011-1h6a1 1 0 110 2h-6a1 1 0 01-1-1z"
              clipRule="evenodd"
            />
          </svg>
        </button>
        <div
          className={cx(
            `${
              isNavExpanded
                ? `${styles.navigationMenu} ${styles.expanded}`
                : [styles.navigationMenu]
            }`
          )}
        >
          <ul className={styles.menuList}>
            <li
              className={styles.menuItem}
              onClick={() => {
                setIsNavExpanded(!isNavExpanded);
              }}
            >
              <Link to="/business">Business</Link>
            </li>
            <li
              className={styles.menuItem}
              onClick={() => {
                setIsNavExpanded(!isNavExpanded);
              }}
            >
              <Link to="/partnership">Partnership</Link>
            </li>
            <li
              className={styles.menuItem}
              onClick={() => {
                setIsNavExpanded(!isNavExpanded);
              }}
            >
              <Link to="/about-us">About Us</Link>
            </li>
          </ul>
        </div>
      </nav>
    </>
    // <div style={props.style} className={Styles.navmaindiv}>
    //   <Navbar light expand="md">
    //     <NavbarBrand href="/" style={props.styles}>
    //       <img src={TruckaeLogo} alt="logo" className={Styles.img} />
    //     </NavbarBrand>
    //     <NavbarToggler onClick={toggle} />
    //     <Collapse isOpen={isOpen} navbar>
    //       <Nav navbar className={Styles.nav2}>
    //         <NavItem>
    //           <NavLink
    //             href={"/#business"}
    //             className={Styles.navlink}
    //             style={props.styles}
    //             onClick={closeit}
    //           >
    //             Business
    //           </NavLink>
    //         </NavItem>
    //         <NavItem>
    //           <NavLink
    //             exact
    //             tag={Link}
    //             activeClassName={Styles.active}
    //             to="/partnership"
    //             style={props.styles}
    //             className={Styles.navlink}
    //             onClick={closeit}
    //           >
    //             Partnership
    //           </NavLink>
    //         </NavItem>
    //         <NavItem>
    //           <NavLink
    //             exact
    //             tag={Link}
    //             activeClassName={Styles.active}
    //             to="/about-us"
    //             style={props.styles}
    //             className={Styles.navlink}
    //             onClick={closeit}
    //           >
    //             About Us
    //           </NavLink>
    //         </NavItem>{" "}
    //       </Nav>
    //     </Collapse>
    //   </Navbar>
    // </div>
  );
}
