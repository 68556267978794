import React from "react";
import styles from "./WhyChoseusIntro.module.css";
import meeting from "../../../images/meeting.png";
import profit2 from "../../../images/profit2.png";
import salary from "../../../images/salary.png";

import ellipse1 from "../../../images/ellipse1.png";
import ellipse3 from "../../../images/ellipse3.png";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function WhyChoseusIntro() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <div className={styles.whychoosemaindiv}>
      <div className={styles.whychoosemaindivdiv}>
        <div className={styles.whychoosemaindivel}>
          <img src={ellipse1} alt="ellipse1" />
        </div>
        <div className={styles.whychoosemaindivel2}>
          <img src={ellipse3} alt="ellipse1" />
        </div>
      </div>
      <div className={styles.whychoosemaindivh2mainn}>
        <div className={styles.whychoosemaindivmain}>
          <div className={styles.whychoosemaindivh2}>
            <h2>Why choose us?</h2>
          </div>
          <div className={styles.whychoosemaindivh222}>
            <div className={styles.whychoosemaindivh222m}>
              <div>
                <img src={meeting} alt="meeting" />
              </div>
              <div>
                <h2>Flexible work timings</h2>
              </div>
              <div>
                <p>
                  Choose your work type,
                  <br /> timing and location
                </p>
              </div>
            </div>
            <div className={styles.whychoosemaindivh222m}>
              <div>
                <img src={profit2} alt="meeting" />
              </div>
              <div>
                <h2>Flexible payment options.</h2>
              </div>
              <div>
                <p>
                  There is no limit to how much you can earn.
                  <br /> You get paid per delivery.
                </p>
              </div>
            </div>
            <div className={styles.whychoosemaindivh222m}>
              <div>
                <img src={salary} alt="meeting"  />
              </div>
              <div>
                <h2>Fixed salary</h2>
              </div>
              <div>
                <p>
                  Get an assured fixed minimum <br />
                  earning as well.
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* <div className={styles.whychoosemaindivtake}>
          <div className={styles.whychoosemaindivtakeh2}>
            <h2>Take a look at what our drivers say</h2>
          </div>
          <Slider {...settings}>
            <div></div>
          </Slider>
        </div> */}
      </div>
    </div>
  );
}
