import React from "react";
import styles from "./FirstMailtemp.module.css";
import first from "../../../assets/mf11.png";
import one from "../../../assets/mf33.png";
import bckg from "../../../assets/backk.png";
import forth from "../../../assets/mf22.png";
import brd from "../../../assets/mf44.png";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import arwcstmrt from "../../../images/arwcstmrt.png";
import arrwleft from "../../../assets/arrwleft.png";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

export default function FirstMailtemp() {
  const topup = () => {
    window.scrollTo(0, 0);
  };
  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div className={className} onClick={onClick}>
        <img src={arrwleft} className={styles.classNameimg1} alt="arw1" />
      </div>
    );
  }
  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div className={className} onClick={onClick}>
        <img src={arwcstmrt} className={styles.classNameimg1} alt="nxtarw" />
      </div>
    );
  }
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 2,
    initialSlide: 0,
    nextArrow: <SamplePrevArrow />,
    prevArrow: <SampleNextArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const content = [
    {
      image: first,
      head: "First mile",
      para: "Our first-mile delivery methods help with effective shipping, packaging, and delivery of the shipments with the use of our Robust Dashboard and Route Optimisation Technologies.",
    },
    {
      image: forth,
      head: "Mid mile",
      para: "Transporting products and customer orders from a fulfilment facility or warehouse to a shop or distribution hub is covered by our middle-mile logistics procedures. Recently, we began exploring for ways to reduce costs while optimising their middle-mile logistics operations.",
    },
    {
      image: one,
      head: "Last mile",
      para: "We provide the customer with a range of options for the Last mile logistics, including choosing the trucks, drivers, delivery model,  and lower fares  which we can execute in a matter of minutes, with the help of our substantial Ground Fleet and Truck Fleet.  The customer will be given access to even real-time tracking of the driver and the shipment using our Technologies",
    },
    {
      image: brd,
      head: "Managed services",
      para: "We base our logistics services on the following tenets.  The correct consumer of the logistics, with the right product, in the right quantity, in the right condition, at the right time, place, and price",
    },
  ];
  return (
    <div className={styles.firstmailmainmaindiv}>
      <div className={styles.firstmailmainmaindivimg}>
        <img src={bckg} alt="backk" />
      </div>
      <div className={styles.firstmailmaindiv}>
        <div className={styles.firstmailmaindivddd}>
          <div className={styles.firstmailmaindivh22}>
            <h2>Our Services</h2>
          </div>
          <div className={styles.firstmailmaindiv2}>
            <Slider {...settings} className={styles.firstmailmaindiv2}>
              {content.map((res, i) => (
                <div className={styles.firstmailmaindivcard}>
                  <div className={styles.firstmailmaindivcardimg}>
                    <img src={res.image} alt="first" />
                  </div>
                  <div className={styles.firstmailmaindivcardcontent}>
                    <h2>{res.head}</h2>
                    <p>{res.para}</p>
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </div>
        {/* <div className={styles.pageupmaindiv} onClick={topup}>
          <p>
            Page Up{" "}
            <span>
              <KeyboardArrowUpIcon />
            </span>
          </p>
        </div> */}
      </div>
    </div>
  );
}
