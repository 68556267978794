import React, { useState } from "react";
import { ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import DangerousIcon from "@mui/icons-material/Dangerous";
import styles from "./DriverModal.module.css";
import CloseIcon from "@mui/icons-material/Close";
import suss from "../../../assets/suss.json";
import Lottie from "react-lottie";

export default function DriverModal(props) {
  // const [modal, setModal] = useState(true);
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: suss,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  return (
    <div>
      <Modal
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className={styles.modalclose}>
            <CloseIcon
              onClick={props.handleClose}
              className={styles.modalclosebtn}
            />
          </div>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            <div className={styles.drminpudivmainh22}>
              <h2>Welcome to our network</h2>
            </div>
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            {props.driverSuccess ? (
              <div className={styles.lott}>
                <Lottie
                  options={defaultOptions}
                  height={125}
                  width={125}
                  loop={false}
                  style={{ color: "red" }}
                />
                <div>
                  <p>Thank You, We will get back to you shortly</p>
                </div>
              </div>
            ) : (
              <div className={styles.drminpudivmain}>
                <div className={styles.drminputlablein}>
                  <label>Name</label>
                  <input
                    type="text"
                    value={props.driverData.name}
                    onChange={(e) => props.driverOnchange(e)}
                    id="name"
                    name="name"
                  />
                </div>
                <div className={styles.drminputlablein}>
                  <label>Mobile Number</label>
                  <input
                    type="number"
                    value={props.driverData.mobileNumber}
                    onChange={(e) => props.driverOnchange(e)}
                    id="mobileNumber"
                    name="mobileNumber"
                  />{" "}
                </div>
                <div className={styles.drminputlablein}>
                  <label>Place</label>
                  <input
                    type="text"
                    value={props.driverData.place}
                    id="place"
                    name="place"
                    onChange={(e) => props.driverOnchange(e)}
                  />{" "}
                </div>
                <div className={styles.getintouchdivbg1forminputsbtn}>
                  <button
                    className={styles.btnsh}
                    onClick={() => props.postDriverData()}
                  >
                    Submit
                  </button>
                </div>
              </div>
            )}
          </Typography>
        </Box>
      </Modal>
    </div>
  );
}
