import React from "react";
import styles from "./Business.module.css";
import NavBar from "../NavBar/NavBar";
import laptopDashboard from "../../assets/laptopHead.webp";
import Footer from "../Footer/Footer";
import mobile1 from "../../assets/businessMobOne.webp";
import mobile2 from "../../assets/businessMobTwo.webp";
import mobile3 from "../../assets/businessMobThree.webp";
import mobile4 from "../../assets/businessMobFour.webp";
import icon1 from "../../assets/Sucessfull.png";
import icon2 from "../../assets/SendfastAPIS.png";
import icon3 from "../../assets/SendPackages.png";
import icon4 from "../../assets/TrackingIcon.png";

const Business = () => {
  return (
    <div>
      <NavBar />
      <section className={styles.mainSection}>
        <div>
          <div className={styles.mainheading}>
            <span className={styles.mainTitleOne}>Repeated customers</span>
            <br />
            <span className={styles.mainTitleTwo}>With our</span>
            <br />
            <span className={styles.mainTitleThree}>
              Faster delivery services
            </span>
          </div>
          {/* <button type="button" className={styles.requestDemoBtn}>
            Request for a demo
          </button> */}
        </div>

        <div className={styles.laptopDashboard}>
          <img src={laptopDashboard} alt="TruckAe Dashboard" />
          <div>
            <h1 className={styles.laptopDashboardHeading}>Easy to use</h1>
            <p className={styles.laptopDashboardDesc}>
              Simple & modern API to integrate with any e-commerce store or use
              our Merchant plugin.
              <br />
              <span>Integrates in hours, not days.</span>
            </p>
          </div>
        </div>
      </section>

      <div className={styles.howitworks}>
        <div className={styles.howitworksDivOne}>
          <div>
            <img src={mobile1} alt="mobile1" className={styles.mobileImg} />
          </div>
          <div>
            <h1 className={styles.howitworksHeading}>Schedule delivery</h1>
            <p className={styles.howitworksDesc}>
              Avoid losses due to cancellations as end customers can schedule a
              change of delivery slot.
            </p>
          </div>
        </div>

        <div className={styles.howitworksDivTwo}>
          <div>
            <img src={mobile2} alt="mobile2" className={styles.mobileImg} />
          </div>
          <div>
            <h1 className={styles.howitworksHeading}>We rate your customer</h1>
            <p className={styles.howitworksDesc}>
              Get data on end customer behavior to restrict/promote features
              like cash on delivery.
            </p>
          </div>
        </div>

        <div className={styles.howitworksDivThree}>
          <div>
            <img src={mobile3} alt="mobile3" className={styles.mobileImg} />
          </div>
          <div>
            <h1 className={styles.howitworksHeading}>Real time tracking</h1>
            <p className={styles.howitworksDesc}>
              End customers can track the delivery status as well, improving end
              user experience.
            </p>
          </div>
        </div>

        <div className={styles.howitworksDivFour}>
          <div>
            <img src={mobile4} alt="mobile4" className={styles.mobileImg} />
          </div>
          <div>
            <h1 className={styles.howitworksHeading}>Route Optimization</h1>
            <p className={styles.howitworksDesc}>
              Our route optimization algorithm ensures that we are able to
              deliver more in less time.
            </p>
          </div>
        </div>
      </div>

      <h1 className={styles.lastMileHeading}>
        HOW OUR LAST MILE PLATFORM WORKS
      </h1>
      <div className={styles.lastMilePlatform}>
        <div className={styles.lastMilePlatformDivOne}>
          <img src={icon2} alt="icon1" className={styles.lastMilePlatformImg} />
          <p className={styles.lastMilePlatformPara}>
            Integrate with Our API’s
          </p>
          <small className={styles.lastMilePlatformDesc}>
            Easy assisted integration with our APIs. Average integration time is
            12 hours.
          </small>
        </div>

        <div className={styles.lastMilePlatformDivTwo}>
          <img src={icon3} alt="icon2" className={styles.lastMilePlatformImg} />
          <p className={styles.lastMilePlatformPara}>Send packages to us</p>
          <small className={styles.lastMilePlatformDesc}>
            Send packages to our warehouse and get a delivery confirmation about
            the receipt of the package.
          </small>
        </div>

        <div className={styles.lastMilePlatformDivThree}>
          <img src={icon4} alt="icon3" className={styles.lastMilePlatformImg} />
          <p className={styles.lastMilePlatformPara}>Real time tracking</p>
          <small className={styles.lastMilePlatformDesc}>
            Track the status of your package at all times from the dashboard.
          </small>
        </div>

        <div className={styles.lastMilePlatformDivFour}>
          <img src={icon1} alt="icon5" className={styles.lastMilePlatformImg} />
          <p className={styles.lastMilePlatformPara}>Delivered sucessfully</p>
          <small className={styles.lastMilePlatformDesc}>
            Our tech enabled platform and huge reach of network partners in Tier
            II and III ensures 100% successful delivery.
          </small>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Business;
