import React, { useState, useEffect } from "react";
import styles from "./DeliveryComp.module.css";
import truck from "../../assets/truck.png";
import cx from "classnames";
import Aos from "aos";
import "aos/dist/aos.css";
import ontimeillustration from "../../assets/d11.png";
import thinklogisticsilllustration from "../../assets/d22.png";
import savemoreillustration from "../../assets/d55.png";
import allllustration from "../../assets/d33.png";
import truckingq from "../../assets/d44.png";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import arwcstmrt from "../../images/arwcstmrt.png";
import arrwleft from "../../assets/arrwleft.png";

export default function DeliveryComp() {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div className={className} onClick={onClick}>
        <img src={arrwleft} className={styles.classNameimg1} alt="arw1" />
      </div>
    );
  }
  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div className={className} onClick={onClick}>
        <img src={arwcstmrt} className={styles.classNameimg1} alt="nxtarw" />
      </div>
    );
  }
  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    nextArrow: <SamplePrevArrow />,
    prevArrow: <SampleNextArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const [localHead, setLocalhead] = useState(1);
  const mcdc = [
    {
      id: 1,
      head: "Always on time",
      img: ontimeillustration,
      content:
        "Our service levels offer the best customer experience possible, and we have a reputation for completing Customer Delivery on time.",
    },
    {
      id: 2,
      head: "An all-inclusive remedy",
      img: thinklogisticsilllustration,
      content:
        "This is a one-stop solution for easily scheduling on-demand bookings, and you won't have to deal with the hassles of managing multiple vendors, whether it be first-mile, mid-mile, or last-mile.",
    },
    {
      id: 3,
      head: "Spend less and Earn More",
      img: savemoreillustration,
      content:
        "With our optimized efficiencies, our customers saved money on their logistical expenditures. This means your company may spend less, save more, and increase its profits.",
    },
    {
      id: 4,
      head: "We accompany you on your Success journey",
      img: allllustration,
      content:
        "Our coverage extends across UAE. Our expertise is in our extensive reach. We will always accompany you on your success Journey.",
    },
    {
      id: 5,
      head: "A platform for better decisions",
      img: truckingq,
      content:
        "With KPI knowledge offered by our powerful and comprehensive technology-based API and dashboards, you can make smarter and faster decisions to improve your business at every level.",
    },
  ];

  const changeHead = (i) => {
    setLocalhead(i);
  };

  return (
    <div className={styles.dcmaindiv}>
      <div className={styles.mcmaindiv}>
        <div className={styles.mcmaindiv2}>
          <div className={styles.mcmaindiv222}>
            {mcdc.map((res, i) => (
              <div className={styles.mcmaindiv2223}>
                <p
                  onClick={() => changeHead(res.id)}
                  className={cx(
                    `${styles.mcmaindiv2p} ${
                      res.id === localHead ? [styles.mcmaindiv2active] : ""
                    }`
                  )}
                >
                  {res.head}
                </p>
              </div>
            ))}
          </div>
        </div>

        <div className={styles.mcmaindiv4}>
          {mcdc.map((res, i) =>
            res.id === localHead ? (
              <div className={styles.mcmaindiv4imghpp}>
                <img data-aos="fade-zoom-in" src={res.img} alt={res.id} />
                <div>
                  <h2 data-aos="fade-up" data-aos-duration="500">
                    {res.head}
                  </h2>
                  <p data-aos="fade-zoom-in" data-aos-easing="ease-in">
                    {res.content}
                  </p>
                </div>
              </div>
            ) : (
              ""
            )
          )}
        </div>
      </div>
      {/* /////////////////////Mobile View//////////////////// */}

      <div className={styles.mcmaindiv412imgh2slider}>
        <Slider {...settings} className={styles.mcmaindiv412imgh2slick}>
          {mcdc.map((res, i) => (
            <div className={styles.mcmaindiv412imgh2}>
              <img src={res.img} alt={res.id} />
              <div>
                <h2>{res.head}</h2>
                <p>{res.content}</p>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
}
