import React from "react";
import styles from "./IntroduceTruck.module.css";
import introtruck from "../../../assets/introtruck1.png";
import WhyChoseusIntro from "../WhyChoseusIntro/WhyChoseusIntro";
import Review from "../Review/Review";

export default function IntroduceTruck(props) {
  return (
    <div className={styles.introtruckdivmainnnnn}>
      <div className={styles.introtruckdiv}>
        <div className={styles.introtruckdivimg}>
          <img src={introtruck} alt="introtruck" />
        </div>
        <div className={styles.introtruckdivimgcontent}>
          <div>
            <h2>
              Introducing Truckae for<span>drivers</span>{" "}
            </h2>
          </div>
          <div>
            <p>
              Owners of truck fleets who have not yet incorporated digital
              technology into their operations or are dissatisfied
              <br /> with their current platform should become our partners
              today.
            </p>
          </div>
          <div>
            <button onClick={props.handleOpen} className={styles.btn}>
              Sign Up
            </button>
          </div>
        </div>
      </div>
      <WhyChoseusIntro />
      {/* <Review /> */}
    </div>
  );
}
