import React from "react";
import styles from "./Frthpage.module.css";
import page from "../../../assets/4page.png";
import loggo from "../../../assets/loggo.png";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import f1 from "../../../assets/I7.png";
import f2 from "../../../assets/I3.png";
import f3 from "../../../assets/I1.png";
import f4 from "../../../assets/I5.png";

import p2 from "../../../assets/I6.png";
import p3 from "../../../assets/I4.png";
import p4 from "../../../assets/I2.png";

import lft from "../../../assets/lft.png";
import rt from "../../../assets/rt.png";

export default function Frthpage() {
  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div className={className} onClick={onClick}>
        <img className={styles.classNamedas} src={lft} alt="arw1" />
      </div>
    );
  }
  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div className={className} onClick={onClick}>
        <img className={styles.classNamedas} src={rt} alt="nxtarw" />
      </div>
    );
  }
  const myStyle = {};
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    initialSlide: 0,
    nextArrow: <SamplePrevArrow />,
    prevArrow: <SampleNextArrow />,
    responsive: [
      // {
      //   breakpoint: 1024,
      //   settings: {
      //     slidesToShow: 3,
      //     slidesToScroll: 3,
      //     infinite: true,
      //   },
      // },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div className={styles.howitwrksmaindivbgmain}>
      <div className={styles.howitwrksmaindivbg} style={myStyle}>
        <img src={page} alt="howitworks" style={{ opacity: "0.4" }} />
      </div>
      <div className={styles.howitwrksmaindivbg1}></div>
      <div className={styles.howitwrksmaindivcontent}>
        <div className={styles.howitwrksmaindivcontent1}>
          <div className={styles.howitwrksmaindivcontent1imgh2}>
            <img src={loggo} alt="logo" />
            <h2>Provides you</h2>
          </div>
          <Slider {...settings}>
            {/* <div className={styles.howitwrksmaindivcontent1imgh2slide}>
              <div className={styles.howitwrksmaindivcontent1imgh2slideimg}>
                <img src={f1} alt="f1" />
              </div>
              <div>
                <h2>Electrical</h2>
                <p>Rage Swap</p>
              </div>
            </div>
            <div className={styles.howitwrksmaindivcontent1imgh2slide}>
              <div className={styles.howitwrksmaindivcontent1imgh2slideimg}>
                <img src={f2} alt="f1" />
              </div>
              <div>
                <h2>Regular</h2>
                <p>Rage+ Rapid</p>
              </div>
            </div> */}
            <div className={styles.howitwrksmaindivcontent1imgh2slide}>
              <div className={styles.howitwrksmaindivcontent1imgh2slideimg}>
                <img src={f3} alt="f1" />
              </div>
              <div>
                <h2>Mini Truck</h2>
                <p>Eicher Pro 3015Xp</p>
              </div>
            </div>

            <div className={styles.howitwrksmaindivcontent1imgh2slide}>
              <div className={styles.howitwrksmaindivcontent1imgh2slideimg}>
                <img src={f4} alt="f1" />
              </div>
              <div>
                <h2>Open Truck</h2>
                <p>Isuzu NPS</p>
              </div>
            </div>

            <div className={styles.howitwrksmaindivcontent1imgh2slide}>
              <div className={styles.howitwrksmaindivcontent1imgh2slideimg}>
                <img src={p2} alt="f1" />
              </div>
              <div>
                <h2>Close Truck</h2>
                <p>Mitsubishi Fuso</p>
              </div>
            </div>

            <div className={styles.howitwrksmaindivcontent1imgh2slide}>
              <div className={styles.howitwrksmaindivcontent1imgh2slideimg}>
                <img src={p3} alt="f1" />
              </div>
              <div>
                <h2>Open Truck</h2>
                <p>Mitsubishi Canter 2021</p>
              </div>
            </div>

            <div className={styles.howitwrksmaindivcontent1imgh2slide}>
              <div className={styles.howitwrksmaindivcontent1imgh2slideimg}>
                <img src={p4} alt="f1" />
              </div>
              <div>
                <h2>Close Truck</h2>
                <p>Isuzu Dmax</p>
              </div>
            </div>
          </Slider>
        </div>
      </div>
    </div>
  );
}
