import React, { useState, useEffect } from "react";
import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import HomePage from "./components/HomePage/Home/Home";
import PartnershipPage from "./components/Partnership/PartnershipPage/PartnershipPage";
import Aboutus from "./components/AboutUsPage/Aboutus/Aboutus";
import FAndQ from "./components/FAndQ/FAndQ";
import PrivacyPolicy from "./components/PrivacyPolicy/PrivacyPolicyNew";
import Terms from "./components/Terms/Terms";
import CancellationRefundPolicy from "./components/Terms/CancellationRefundPolicy";
import GoToTop from "./components/GoToTop";
import Business from "./components/Business/Business";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        {/* <GoToTop /> */}
        <Routes>
          <Route index path="/" element={<HomePage />} />
          <Route index path="/business" element={<Business />} />
          <Route index path="/partnership" element={<PartnershipPage />} />
          <Route index path="/about-us" element={<Aboutus />} />

          <Route index path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route index path="/terms-of-service" element={<Terms />} />
          <Route index path="/partner-terms-of-service" element={<Terms />} />
          <Route
            index
            path="/cancellation-policy"
            element={<CancellationRefundPolicy />}
          />

          <Route index path="/fanda" element={<FAndQ />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
