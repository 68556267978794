import React, { Suspense } from "react";
import NavBar from "../../NavBar/NavBar";
import DelveryMoving from "../DelveryMoving/DelveryMoving";
//import HowItWorks from "../HowItWorks/HowItWorks";
import Frthpage from "../Frthpage/Frthpage";
import LandingPage from "../LandingPage/LandingPage";

import styles from "./Home.module.css";
import Spyindicator from "../Spyindicator/Spyindicator";
import GetInTouch from "../GetInTouch/GetInTouch";
// import FirstMail from "../FirstMail/FirstMail";
import FirstMail from "../FirstMail/FirstMailtemp";

import Footer from "../../Footer/Footer";

const HowItWorks = React.lazy(() => import("../HowItWorks/HowItWorks"));

export default function Home() {
  return (
    <>
      <NavBar />
      <LandingPage />
      <div id="business">
        <Suspense fallback={<></>}>
          <HowItWorks />
        </Suspense>
      </div>

      <DelveryMoving />
      <Frthpage />
      <FirstMail />
      {/* <Spyindicator /> */}
      {/* <GetInTouch /> */}
      <Footer />
    </>
  );
}
