import React from "react";
import NavBar from "../NavBar/NavBar";
import Footer from "../Footer/Footer";
import styles from "./terms.module.css";

export default function Terms() {
  return (
    <div>
      <NavBar />
      <div className={styles.privacypolicy}>
        <div className={styles.privacypolicy1}>
          <div className={styles.privacypolicyh2div}>
            <h2>Terms of Service</h2>
          </div>
          <div className={styles.privacypolicyh2divp}>
            <p>
              "Welcome to Truckae. If you continue to browse and use our
              app/website you are agreeing to comply with and be bound by these
              terms and conditions (Terms), which together with our privacy
              policy govern Truckae Technologies DMCC relationship with you in
              relation to this app/website and the Services (as defined below)."
              <br />
              In the event there is a conflict between the terms and conditions
              specified herein and the provisions of any other document executed
              between the parties hereto, the terms and conditions specified
              herein will prevail.
            </p>
          </div>
          <div className={styles.privacypolicyh2divpart1div}>
            {/* //////////////point 1 ///////////////////////// */}

            <div className={styles.privacypolicyh2divpart1div22}>
              <div className={styles.privacypolicyh2divpart1divp1}>
                <h3>DEFINITIONS</h3>
                <p>The following definitions apply to these Terms.</p>
                <ul>
                  <li>
                    <p>
                      "Applicable Law" means all national and international
                      laws, statutes, decrees, ordinance, regulations,
                      guidelines, policies, rules, bye-laws, notifications,
                      directions, directives and orders of any local, municipal,
                      territorial, provincial, national or any other duly
                      constituted governmental authority or agency of the United
                      Arab Emirates.
                    </p>
                  </li>
                  <li>
                    <p>
                      "Carriage" means and includes the whole of the operations
                      and services undertaken by the Service Provider in
                      connection with the Consignment.
                    </p>
                  </li>
                  <li>
                    <p>
                      "Consignment" means any package, parcel, sachet, or
                      freight which is or are given to and accepted by the
                      Service Provider for carriage.
                    </p>
                  </li>
                  <li>
                    <p>
                      "Customer" means a person, company or other entity which
                      utilises the Services of the Service Provider.
                    </p>
                  </li>
                  <li>
                    <p>
                      “Dangerous Goods” means goods classified as dangerous as
                      per the International Civil Aviation Organisation
                      Technical Instructions, International Air Transport
                      Association Dangerous Goods Regulations, International
                      Maritime Dangerous Goods Code, Agreement concerning the
                      International Carriage of Dangerous Goods by Road or other
                      applicable local / national regulations for transport.
                    </p>
                  </li>
                  <li>
                    <p>
                      “Delivery” means the tender of the Consignment to the
                      Receiver or Consignee.
                    </p>
                  </li>
                  <li>
                    <p>
                      "Platform" means either the website or any mobile
                      application which Truckae (as defined below) provides.
                    </p>
                  </li>
                  <li>
                    <p>
                      "Prohibited Items" means any goods or materials, the
                      Carriage of which is prohibited by Applicable Law.
                    </p>
                  </li>
                  <li>
                    <p>
                      “Receiver” or “Consignee” shall refer to the recipient,
                      addressee or consignee of the Consignment.
                    </p>
                  </li>
                </ul>
              </div>
            </div>

            <div className={styles.privacypolicyh2divpart1div22}>
              <div className={styles.privacypolicyh2divpart1divp1}>
                <h3>USER(S) AGREEMENT</h3>
                <p>
                  This agreement applies to user(s) if user(s) are visitors,
                  registered - free or paid user(s) who access the web site for
                  any purpose. It also applies to any legal entity which may be
                  represented by you under actual or apparent authority. User(s)
                  may use this site solely for their own personal or internal
                  purposes.
                </p>
                <ul>
                  <li>
                    <p>
                      “Services" means the collection, Delivery and carriage of
                      a Consignment.
                    </p>
                  </li>
                  <li>
                    <p>
                      “Service Provider” means anyone who has registered on the
                      Truckae platform with an intent to provide transportation
                      services to the individuals / businesses who are seeking
                      transportation services.
                    </p>
                  </li>
                  <li>
                    <p>
                      “User" means any individual or business
                      entity/organisation that accesses the Platform, regardless
                      of whether they are a visitor or registered (either free
                      or paid) on the Platform.
                    </p>
                  </li>
                  <li>
                    <p>
                      "We", "us", "our", "Carrier", "Truckae", "Company" shall
                      refer to Truckae Technologies DMCC, its employees,
                      authorised agents and its independent contractors and the
                      entities that carry or undertake to carry the Consignment
                      hereunder or perform any other services incidental
                      thereto.
                    </p>
                  </li>
                  <li>
                    <p>
                      “You”, “your”, “Consignor” shall refer to the sender of
                      the Consignment and the individual engaging the Services
                      via the Platform.
                    </p>
                  </li>
                </ul>
              </div>
            </div>
            <div className={styles.privacypolicyh2divpart1div22}>
              <div className={styles.privacypolicyh2divpart1divp1}>
                <h3>PLATFORM USER TERMS</h3>
                <p>
                  These Platform User Terms apply to all Users who access the
                  Platform for any purpose. Truckae may change, modify, amend,
                  or update these Platform User Terms from time to time with or
                  without any prior notification to Users and the amended and
                  reinstated Platform User Terms shall be effective immediately
                  on posting on the Platform. If Users do not wish to be bound
                  by the revised Platform User Terms, Users must stop using the
                  Platform. Continuous use of the Platform will signify a User's
                  acceptance of the revised Platform User Terms.
                </p>
              </div>
            </div>

            <div className={styles.privacypolicyh2divpart1div22}>
              <div className={styles.privacypolicyh2divpart1divp1}>
                <h3>INTELLECTUAL PROPERTY RIGHTS</h3>
                <p>
                  Truckae is the sole owner or lawful licensee of all the rights
                  to the Platform and any of its content. Platform content
                  includes, but is not limited to, the Platform design, layout,
                  text, images, graphics, sound and video. Platform content
                  embodies trade secrets and intellectual property rights
                  protected under worldwide copyright and other laws. All title,
                  ownership and intellectual property rights in the Platform
                  content shall remain with Truckae, its affiliates or
                  licensor's of Truckae content, as the case may be.
                  <br />
                  <br />
                  All rights, not otherwise claimed under these Platform User
                  Terms or by Truckae, are hereby reserved. The information
                  contained in this Platform is intended, solely to provide
                  general information for the personal use of the User, who
                  accepts full responsibility for its use. Truckae does not
                  represent or endorse the accuracy or reliability of any
                  information, or advertisements (collectively, the "content")
                  contained on, distributed through, or linked, downloaded or
                  accessed from any of the Services contained on this Platform,
                  or the quality of any products, information or other materials
                  displayed, or obtained by Users as a result of an
                  advertisement or any other information or offer in or in
                  connection with the Services.
                  <br />
                  <br />
                  Truckae accepts no responsibility for any errors or omissions,
                  or for the results obtained from the use of the information on
                  the Platform. All information on this Platform is provided "AS
                  IS " with no guarantee of completeness, accuracy, suitability,
                  availability, timeliness or of the results obtained from the
                  use of this information, and without warranty of any kind,
                  express or implied, including, but not limited to warranties
                  of performance, merchantability and fitness for a particular
                  purpose. Nothing herein shall to any extent be substituted for
                  the independent investigations and the sound technical and
                  business judgment of Users. In no event shall Truckae be
                  liable for any direct, indirect, incidental, punitive, or
                  consequential damages of any kind whatsoever with respect to
                  the Services. Users of this site must hereby acknowledge that
                  any reliance upon any content shall be at the Users sole risk.
                  <br />
                  <br />
                  Truckae reserves the right, in its sole discretion and without
                  any obligation, to make improvements to, or correct any error
                  or omissions in any portion of the Platform.
                  <br />
                  <br />
                  All related icons and logos are registered trademarks or
                  trademarks or service marks of Truckae in various
                  jurisdictions and are protected under applicable copyright,
                  trademark and other proprietary rights laws. The unauthorized
                  copying, modification, use or publication of these marks is
                  strictly prohibited.
                  <br />
                  <br />
                  All content on this Platform is the copyright of Truckae
                  except for any third party content or links to third party
                  websites on our Platform.
                  <br />
                  <br />
                  Systematic retrieval of Truckae content from the Platform to
                  create or compile, directly or indirectly, a collection,
                  compilation, database or directory (whether through robots,
                  spiders, automatic devices or manual processes) without
                  written permission from Truckae is prohibited.
                  <br />
                  <br />
                  In addition, use of any Platform content for any purpose not
                  expressly permitted in this Agreement is prohibited and may
                  invite legal action. As a condition of access to and use of
                  the Platform, Users agree that they will not use the Platform
                  to infringe the intellectual property rights of others in any
                  way. Truckae reserves the right to terminate the account of
                  Users upon any infringement of the rights of others in
                  conjunction with use of the Platform, or if Truckae believes
                  that a User's conduct is harmful to the interests of Truckae,
                  its affiliates, or other Users, or for any other reason in
                  Truckae's sole discretion, with or without cause.
                  <br />
                  <br />
                  Users shall be liable to indemnify Truckae for any losses or
                  expenses incurred by Truckae due to any infringement of
                  intellectual property rights owned by Truckae without
                  prejudicing Truckae's right to bring any legal action against
                  Users
                </p>
              </div>
            </div>

            <div className={styles.privacypolicyh2divpart1div22}>
              <div className={styles.privacypolicyh2divpart1divp1}>
                <h3>LINKS TO THIRD PARTY SITES</h3>
                <p>
                  Links to third party sites are provided on the Platform as a
                  convenience to Users and Truckae has no control over such
                  sites, including content and resources provided by them.
                  <br />
                  <br />
                  Truckae may allow Users to access content, products or
                  Services offered by third parties through hyperlinks (in the
                  form of word link, banners, channels or otherwise) to such
                  third parties' websites. Users are cautioned to read such
                  sites' terms and conditions and/or privacy policies before
                  using such sites in order to be aware of the terms and
                  conditions of a User's use of such sites. Users acknowledge
                  that Truckae has no control over such third parties' sites,
                  does not monitor such sites, and Truckae shall not be
                  responsible or liable to anyone for such third-party site, or
                  any content, products or Services made available on such a
                  site. Users shall review Truckae's Privacy Policy and abide by
                  Truckae's Privacy Policy at the time of the User's interaction
                  with Truckae, with respect to and concerning any information
                  and data.
                </p>
              </div>
            </div>

            <div className={styles.privacypolicyh2divpart1div22}>
              <div className={styles.privacypolicyh2divpart1divp1}>
                <h3>TERMINATION</h3>
                <p>
                  Most content and some of the features on the Platform are made
                  available to Users free of charge. However, Truckae reserves
                  the right to terminate access to certain areas or features of
                  the Platform at any time for any reason, with or without
                  notice. Truckae reserves the right to deny access to
                  particular Users to the Platform without any prior
                  notice/explanation in order to protect the interests of
                  Truckae and/or other Users to the Platform.
                </p>
              </div>
            </div>

            <div className={styles.privacypolicyh2divpart1div22}>
              <div className={styles.privacypolicyh2divpart1divp1}>
                <h3>TERMS & CONDITIONS FOR USE OF OUR SERVICE</h3>
                <p>
                  The Customer shall pay the fare (as agreed), parking charges,
                  additional night surcharge (where applicable) and any fee or
                  levy presently payable or hereinafter imposed by the
                  Applicable Law or required to be paid for using the Services.
                  <br />
                  <br />
                  The Customer agrees and accepts that the Services are provided
                  by the Service Provider at the Customer's sole risk, and
                  further acknowledges that the Company disclaims all
                  representations and warranties of any kind, whether express or
                  implied.
                  <br />
                  <br />
                  The customer shall ensure that he/she will not indulge in any
                  of the following activities while availing the service:
                </p>

                <ul>
                  <li>
                    <p>
                      Soiling or damaging the body and/or any other interiors of
                      the vehicles.
                    </p>
                  </li>
                  <li>
                    <p>
                      Misusing, soiling or damaging any of the devices
                      (technical/non-technical) in the vehicle.
                    </p>
                  </li>
                  <li>
                    <p>
                      Asking the driver to break any Traffic/RTO/City Police
                      and/or government rules for any purpose. The driver has
                      the right to refuse such a request by the customer. The
                      driver also has the right to refuse such a pick-up.
                    </p>
                  </li>
                  <li>
                    <p>
                      Pressurizing the driver to overload truck with the
                      Consignment than the allowed limit.The Customer agrees to
                      indemnify the Company from and against and in respect of
                      any or all liabilities, losses, charges and expenses
                      (including legal fees and costs on a full indemnity basis)
                      claims, demands, actions and proceedings which the Company
                      may incur or sustain directly or indirectly from or by any
                      reason of or in relation to the use or proposed use of the
                      Services by the Customer and shall pay such sums on demand
                      on behalf of the Company.
                    </p>
                  </li>
                </ul>

                <p>
                  The Company is hereby authorized to use the location-based
                  information provided by any telecommunication company when the
                  Customer uses a mobile phone to make a vehicle booking. The
                  location-based information will be used only to facilitate and
                  improve the probability of locating a mini-truck for the
                  Customer.
                  <br />
                  <br />
                  The Customer agrees that the Company may disclose to all
                  companies within its group, or any government body as so
                  required by the Applicable Law or by directive or request from
                  any government body, the particulars of the Customer in the
                  possession of Company in any way as the Company, in its
                  absolute discretion, deems necessary to carry out the
                  Services.
                  <br />
                  <br />
                  The Company shall be entitled, at any time without giving any
                  reason, to terminate the booking of the vehicle by the
                  Customer.
                  <br />
                  <br />
                  In case of items lost during transit, the Company will try to
                  locate the items on a "reasonable efforts" basis but shall not
                  be responsible for the loss, damage, theft or misappropriation
                  to / of items in transit. The Company aggregates the Service
                  Providers for the purposes of providing them with requests
                  seeking Services by Customers. In the event of loss of any
                  item, Customers shall not have any right to withhold the fees
                  to be made to the Company. Further, in the event any payments
                  from the Customer to the Company are pending for the period
                  prescribed by the Company in its respective invoice /
                  statement, the Company reserves the right, in accordance with
                  the Applicable Law, to exercise particular lien over the
                  Consignment until full payment is made to the Company for the
                  Services. Furthermore, Customers will be liable to indemnify
                  the Company against any loss, damage or expenses incurred by
                  it resulting from its custody of the Consignment during this
                  period.
                  <br />
                  <br />
                  The Customer agrees to inform the Company of any complaint in
                  respect of the Services or the use of the vehicles in writing
                  within twenty-four (24) hours of the circumstances giving rise
                  to the complaint.
                  <br />
                  <br />
                  The Company shall not be liable for the conduct of the drivers
                  used in providing the Services. However, the Company
                  encourages the Customer to notify it, of any complaints that
                  the Customer may have against a driver.
                  <br />
                  <br />
                  The Company shall be entitled to add to, vary or amend any or
                  all these Terms at any time and the Customer shall be bound by
                  such addition, variation or amendment once such addition,
                  variation or amendment is incorporated into these Terms on the
                  Company's Platform on the date that Company may indicate that
                  such addition, variation or amendment is to come into effect.
                  <br />
                  <br />
                  All the calls made to the Company's call centre are recorded
                  by the Company for quality and training purposes. In the event
                  the Customer places a query on the Platform including any
                  query with respect to our Services, applicable fees or Terms
                  and Conditions of Service, the Customer hereby expressly
                  consents to receiving our responses, whether by way of
                  telephone calls or electronic mail, to such query and all
                  related information with respect to our Services. For the
                  avoidance of doubt, related information includes without
                  limitation marketing and/or commercial information. You, the
                  Customer, understand, agree and acknowledge that such
                  information shall in no event, qualify as unsolicited
                  commercial communication.
                  <br />
                  <br />
                  All cancellations made five (5) minutes after driver
                  allocation will incur a cancellation fee. The cancellation of
                  four (4) bookings in one day, after driver allocation, will
                  temporarily suspend a Customer's account for twenty-four (24)
                  hours.
                  <br />
                  <br />
                  In case a road toll charge is imposed in respect of the
                  Services provided to the Customer, both the outward and return
                  toll fare will be charged.
                </p>
              </div>
            </div>

            <div className={styles.privacypolicyh2divpart1div22}>
              <div className={styles.privacypolicyh2divpart1divp1}>
                <h3>CONFIDENTIALITY</h3>
                <p>
                  Customers shall not disclose any information received under
                  the Terms with the Company to any third party. Access to any
                  information which pertains to business of the Company shall be
                  kept confidential to the extent it might adversely impact the
                  Company's business. Customers shall be liable to indemnify the
                  Company against any loss of business or reputation due any
                  action that contravenes this.
                </p>
              </div>
            </div>

            <div className={styles.privacypolicyh2divpart1div22}>
              <div className={styles.privacypolicyh2divpart1divp1}>
                <h3>DISCLAIMER</h3>
                <p>
                  All vehicles registered with the Company are constantly
                  tracked for business and security reasons only. It is hereby
                  made expressly clear to you, as the Customer, that the Company
                  does not own any vehicle nor does it directly or indirectly
                  employ any driver of the vehicles. Vehicles and drivers are
                  all supplied by third parties and the Company disclaims any
                  and all liabilities in respect of the drivers and the vehicles
                  alike.
                  <br />
                  <br />
                  The Customer agrees that the Company has the right to use the
                  Customer contact information for its own marketing purposes.
                  The Company may send regular SMS updates to the mobile numbers
                  registered with it.
                </p>
              </div>
            </div>

            <div className={styles.privacypolicyh2divpart1div22}>
              <div className={styles.privacypolicyh2divpart1divp1}>
                <h3>YOUR ACCEPTANCE OF OUR TERMS AND CONDITIONS</h3>
                <p>
                  By providing the Service Provider with a Consignment, Users
                  and/or Customers accept these Terms and/or the contract of
                  Carriage and/or the contract for the performance of other
                  services on behalf of the Customer and/or anyone else who has
                  an interest in the Consignment or the performance of other
                  services. These Terms also cover and can be invoked by anyone
                  the Company uses or sub-contracts to collect, transport,
                  deliver the Consignment or perform other services as well as
                  the Company's employees, directors and agents. Only the
                  Company's authorised officers may agree to a variation of
                  these Terms in writing. When the Customer the Consignment with
                  oral or written instructions that conflict with the Company's
                  Terms the Company shall not be bound by such instructions to
                  the extent that they conflict.
                </p>
              </div>
            </div>

            <div className={styles.privacypolicyh2divpart1div22}>
              <div className={styles.privacypolicyh2divpart1divp1}>
                <h3>YOUR OBLIGATIONS</h3>
                <ul>
                  <li>
                    <div>
                      <p>
                        In respect of each Consignment, the Customer represents
                        and warrants that:
                      </p>
                      <ul>
                        <li>
                          <p>
                            The contents of the Consignment (including but not
                            limited to weight and number of items) have been
                            properly described on the Platform when booking the
                            Services and all necessary information and
                            documentation, including invoices and permits, are
                            provided on the Platform
                          </p>
                        </li>
                        <li>
                          <p>
                            The contents of the Consignment are not Prohibited
                            Items and/or are not restricted by the applicable
                            regulations
                          </p>
                        </li>
                        <li>
                          <p>
                            The Customer will not supply to the Company any
                            Dangerous or prohibited Goods, the Customer is
                            required to provide a declaration stating the
                            category and other details, that is needed, properly
                            and accurately in accordance with Applicable Law and
                            neither the Customer nor the Consignee is a person
                            or organisation with whom the Company or the
                            Customer may not legally trade under Applicable Law
                          </p>
                        </li>
                        <li>
                          <p>
                            The Consignment is accepted on an “As-is” basis and
                            shall be attempted to be delivered on the “As-was”
                            basis on a best-efforts basis. Further it has been
                            accepted on “said to contain” basis only without any
                            checks or verification of the actual contents,
                            irrespective of whether it was feasible or practical
                            to do so.
                          </p>
                        </li>
                        <li>
                          <p>
                            All statements and information and documents
                            provided by the Customer relating to the Consignment
                            true and correct and the Customer acknowledges that
                            in the event that the Customer makes untrue or
                            fraudulent statement about the Consignment or any of
                            its contents, the Customer would risk a civil claim
                            and/or criminal prosecution, the penalties for which
                            may include forfeiture and sale. The Customer agrees
                            to indemnify the Company / Service Provider and hold
                            the Company / Service Provider harmless from any
                            claims that may be brought against the Company /
                            Service Provider or the Company's agents arising
                            from the information provided by the Customer
                          </p>
                        </li>
                        <li>
                          <p>
                            The Service Provider is authorised to deliver the
                            Consignment to the address provided when booking the
                            Services on the Platform and without prejudice to
                            the foregoing, it is expressly agreed that in the
                            case of a Delivery confirmation sent by the Company
                            by SMS / messaging services / app-notification /
                            e-mails, if no-response is received within
                            twenty-four (24) hours it will be considered as
                            confirmation of the Delivery
                          </p>
                        </li>
                        <li>
                          <p>
                            That the Customer has declared the correct weight of
                            the Consignment and the Customer will provide any
                            special equipment the Company may need to load or
                            unload the Consignment on or off the Company's
                            vehicles
                          </p>
                        </li>
                        <li>
                          <p>
                            That if the Customer has asked the Company to charge
                            the Receiver or a third party and the Receiver or
                            third party does not pay the Company / Service
                            Provider, the Customer will promptly settle the dues
                            together with an administration fee in full within
                            seven (7) days of the Company sending the Customer
                            the statement of dues; and
                          </p>
                        </li>
                        <li>
                          <p>
                            That the Customer has complied with all Applicable
                            Laws in respect of the Consignment.
                          </p>
                        </li>
                      </ul>
                    </div>
                  </li>
                  <li>
                    <div>
                      <p>
                        The Customer understands, agrees and acknowledges that
                        the Services are not suitable for transportation of
                        valuables like cash, gold, silver, diamond, precious
                        stones, jewels or jewellery, expensive luxury items etc.
                        (“Valuables”). If the Customer hands over or loads the
                        vehicles with Valuables for transportation, any loss,
                        damage, theft, misappropriation to or of the Consignment
                        shall be at the Customer's risk and not Company's, for
                        the reasons mentioned in these Terms and without
                        prejudice, the Company shall not only have the right to
                        explicitly and specifically disclaim any liability
                        and/or responsibility arising or accruing from the
                        damage, loss, theft, misappropriation to or of the
                        Consignment or any contents of the Consignment, but also
                        the right to claim indemnification from the Customer
                        where the Company has suffered loss of reputation or
                        goodwill due to the Customer's actions of breaching
                        these Terms.
                      </p>
                      <ul>
                        <li>
                          <div>
                            <p>
                              The Customer understands, agrees and acknowledges
                              that if the Consignment or any contents thereof
                              are of the value exceeding AED 2,500 (AED Two
                              Thousand and Five Hundred), the Customer shall
                              have the Consignment insured from an insurance
                              company in the United Arab Emirates before handing
                              over the Consignment for transportation. Maximum
                              liability towards Customer is restricted to the
                              lower of:
                            </p>
                            <ul>
                              <li>
                                <p>Value of goods damaged; OR</p>
                              </li>
                              <li>
                                <p>
                                  Ten times the freight amount paid or payable
                                </p>
                              </li>
                            </ul>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </li>

                  <li>
                    <div>
                      <p>
                        In absence of adequate insurance to cover loss of goods
                        in transit or in absence of wrong or non-declaration of
                        the Consignment details or value, and whether insured or
                        not, the transportation of the Consignment shall be
                        entirely at the Customer's risk and not the Company's
                        and the Company explicitly and specifically disclaims
                        any liability and/or responsibility arising or accruing
                        from the damage, loss, theft, misappropriation or any
                        insurable loss to or of the Consignment or any contents
                        of the Consignment and the Customer further agrees to
                        indemnify the Company in all those cases where the
                        Company has suffered loss of reputation or goodwill due
                        to the Customer's actions of breaching these Terms.
                      </p>
                    </div>
                  </li>

                  <li>
                    <div>
                      <p>
                        The Customer certifies that all statements and
                        information the Customer provides relating to the
                        transportation of the Consignment true and correct. The
                        Customer acknowledges that in the event that the
                        Customer makes untrue or fraudulent statements about the
                        Consignment or any of its contents the Customer risks a
                        civil claim and/or criminal prosecution the penalties
                        for which include forfeiture and sale of the Customer's
                        Consignment. To the extent that the Company may
                        voluntarily assist the Customer in completing the
                        required customs and other formalities such assistance
                        will be rendered at the Customer's sole risk. The
                        Customer agrees to indemnify the Company and hold the
                        Company harmless from any claims that may be brought
                        against the Company arising from the information the
                        Customer provides to the Company and any costs the
                        Company will incur regarding this, and pay any
                        administration fee the Company may charge the Customer
                        for providing the Services described in this Paragraph.
                      </p>
                    </div>
                  </li>

                  <li>
                    <div>
                      <p>
                        The Customer agrees and acknowledges that the use of the
                        Services is at the Customer's sole risk and that the
                        Company excludes all representations and warranties of
                        any kind, whether express or implied as to condition,
                        suitability, reliability, timeliness, quality,
                        merchantability and fitness for any purposes to the
                        fullest extent permitted by Applicable Law. Without
                        prejudice to the above, the Company makes no
                        representation or warranties with respect to:
                      </p>

                      <ul>
                        <li>
                          <p>
                            The Services meeting the Customer's requirements
                          </p>
                        </li>
                        <li>
                          <p>
                            The Services being uninterrupted, timely, secure, or
                            error-free
                          </p>
                        </li>
                        <li>
                          <p>
                            Any responsibility or liability for any loss or
                            damage, howsoever caused or suffered by the Customer
                            arising out of the use of Services offered by
                            Service Provider or due to the failure of the
                            Service Provider to provide Services to the Customer
                            for any reason whatsoever including but not limited
                            to the Customer's non-compliance with the Services'
                            recorded voice instructions, malfunction, partial or
                            total failure of any network terminal, data
                            processing system, computer tele-transmission or
                            telecommunications system or other circumstances
                            whether or not beyond the control of the Company or
                            any person or any organization involved in the above
                            mentioned systems;
                          </p>
                        </li>
                        <li>
                          <p>
                            Any liability for any damages of any kind arising
                            from the use of the Service , including, but not
                            limited to direct, indirect, incidental, punitive,
                            and consequential damages;
                          </p>
                        </li>
                        <li>
                          <p>
                            Any additional or extra charges for far off
                            locations and toll charges; and
                          </p>
                        </li>
                        <li>
                          <p>
                            Any alternate arrangement(s) if the vehicle has not
                            reached the Delivery destination due to any reason.
                          </p>
                        </li>
                      </ul>
                    </div>
                  </li>

                  <li>
                    <div>
                      <p>
                        The Company is not liable if the Company does not fulfil
                        any obligations towards the Customer at all as a result
                        of:
                      </p>
                      <ul>
                        <li>
                          <div>
                            <p>
                              Circumstances beyond the Customer's control such
                              as (but not limited to):
                            </p>
                            <ul>
                              <li>
                                <p>
                                  Acts of God including earthquakes, cyclones,
                                  storms, flooding, fire, disease, fog, snow or
                                  frost or other natural calamities or
                                  disasters;
                                </p>
                              </li>
                              <li>
                                <p>
                                  Force majeure including (but not limited to)
                                  war, epidemics, pandemics, accidents, acts of
                                  public enemies, strikes, embargoes, perils of
                                  the air, local disputes or civil commotions;
                                </p>
                              </li>
                              <li>
                                <p>
                                  National or local disruptions in air or ground
                                  transportation networks and mechanical
                                  problems to modes of transport or machinery;
                                </p>
                              </li>
                              <li>
                                <p>
                                  Latent defects or inherent vice in the
                                  contents of the Consignment; and
                                </p>
                              </li>
                              <li>
                                <p>
                                  Criminal acts of third parties such as theft
                                  and arson,
                                </p>
                              </li>
                            </ul>
                          </div>
                        </li>

                        <li>
                          <div>
                            <p>
                              The Customer's acts or omissions or those of third
                              parties such as:
                            </p>
                            <ul>
                              <li>
                                <p>
                                  The Customer being in breach of (or any other
                                  party claiming an interest in the Consignment
                                  causing the Customer to breach) the Customer's
                                  obligations under these Terms;
                                </p>
                              </li>
                            </ul>
                          </div>
                        </li>

                        <li>
                          <p>
                            The contents of the Consignment consisting of any
                            article that is a Prohibited Item, Dangerous Goods
                            or Valuables even though the Service Provider may
                            have accepted the Consignment by mistake or the
                            Customer has willingly handed it over to the Company
                            without notifying, informing or declaring to the
                            Company. Acceptance of the Consignment is without
                            prejudice to the terms and conditions mentioned
                            herein.
                          </p>
                        </li>
                      </ul>
                    </div>
                  </li>

                  <li>
                    <p>
                      The Customer agrees to indemnify the Company and hold the
                      Company harmless from any liabilities the Company may
                      suffer or any costs, damages or expenses, including legal
                      costs, the Company incurs either to the Customer or to
                      anyone else arising out of the Customer being in breach of
                      any of these warranties and representations mentioned
                      herein even if the Company inadvertently accepts a
                      Consignment that contravenes any of the Customer's
                      obligations.
                    </p>
                  </li>
                </ul>
              </div>
            </div>

            <div className={styles.privacypolicyh2divpart1div22}>
              <div className={styles.privacypolicyh2divpart1divp1}>
                <h3>REJECTED CONSIGNMENTS</h3>
                <p>
                  If the Receiver refuses to accept Delivery, the Company will
                  attempt to contact the Customer and agree to the next action
                  if it is appropriate. The Customer agrees to pay the Company
                  any costs incurred by the Company in forwarding, disposing of
                  or returning the Consignment and the Company's charges (if
                  any) for the agreed appropriate next action.
                  <br />
                  If the Customer terminates the Agreement with the Company with
                  respect to any Consignment, the Customer shall be liable to
                  pay the Company the entire fees and other expenses so incurred
                  with respect to such Consignment.
                </p>
              </div>
            </div>

            <div className={styles.privacypolicyh2divpart1div22}>
              <div className={styles.privacypolicyh2divpart1divp1}>
                <h3>CLAIMS BROUGHT BY THIRD PARTIES</h3>
                <p>
                  The Customer undertakes not to permit any other person who has
                  an interest in the Consignment to bring a claim or action
                  against the Company arising out of Carriage even though the
                  Company may have been negligent or in default and if a claim
                  or action is made, the Customer will indemnify the Company
                  against the consequences of the claim or action and the costs
                  and expenses the Company incurs in defending it.
                </p>
              </div>
            </div>

            <div className={styles.privacypolicyh2divpart1div22}>
              <div className={styles.privacypolicyh2divpart1divp1}>
                <h3>GOVERNING LAW AND JURISDICTION</h3>
                <p>
                  These Terms shall be governed by and construed in accordance
                  with the laws of the United Arab Emirates as applied in the
                  Emirate of Dubai/ DMCC Freezone.
                  <br />
                  The Courts of Dubai shall have exclusive jurisdiction over any
                  disputes, differences or claims arising out of or in
                  connection with these Terms or any Services provided by us
                  pursuant to these Terms.
                </p>
              </div>
            </div>

            <div className={styles.privacypolicyh2divpart1div22}>
              <div className={styles.privacypolicyh2divpart1divp1}>
                <h3>DISCLAIMER</h3>
                <p>
                  The information contained on this Platform is for general
                  information purposes only. The information is provided by the
                  Company and while the Company endeavours to keep the
                  information up to date and correct, the Company makes no
                  representations or warranties of any kind, express or implied,
                  about the completeness, accuracy, reliability, suitability or
                  availability with respect to the Platform or the information,
                  products, services, or related graphics contained on the
                  Platform for any purpose. Any reliance the Customer places on
                  such information is therefore strictly at the Customer's own
                  risk.
                  <br />
                  In no event will the Company be liable for any loss or damage
                  including without limitation, indirect or consequential loss
                  or damage, or any loss or damage whatsoever arising from loss
                  of data or profits arising out of, or in connection with, the
                  use of this Platform.
                  <br />
                  Through this Platform, Customers' are able to link to other
                  websites which are not under the control of the Company. The
                  Company has no control over the nature, content and
                  availability of those sites. The inclusion of any links does
                  not necessarily imply a recommendation or endorse the views
                  expressed within them.
                  <br />
                  Every effort is made to keep the portal up and running
                  smoothly. However, the Company provides no assurance and takes
                  no responsibility for, and will not be liable for, the
                  Platform being temporarily unavailable due to technical issues
                  beyond the Company's control, or for the convenience of the
                  Company.
                  <br />
                  Customers acknowledge that Truckae does not provide
                  transportation or logistics services or function as a
                  transportation carrier and that all such transportation or
                  logistics services are provided by independent third party
                  contractors who are not employed by Truckae or any of its
                  affiliates.
                  <br />
                  Customer acknowledges that Truckae will solely provide the
                  Platform in order for the Customer to engage the services of
                  the Service Provider . Truckae may, as the intermediary
                  between the Customer and Service Provider use a third party
                  payment provider to collect any fees owed by the Customer for
                  the Services. Customers use the Platform, to collect the fees
                  for the Services and acknowledges that Truckae does so on
                  behalf of the Service Provider and where applicable, to
                  collect any amounts owed to Truckae by the Customer.
                </p>
              </div>
            </div>

            <div className={styles.privacypolicyh2divpart1div22}>
              <div className={styles.privacypolicyh2divpart1divp1}>
                <h3>GENERAL TERMS & CONDITIONS</h3>
                <p>
                  This Agreement supersedes all prior agreements and
                  understandings (whether written or oral) made between Truckae
                  and the Service Provider in respect of the vehicle(s)
                  specified in Attachment
                  <br />
                  <br />
                  This Agreement shall commence on the date set out on the front
                  page of this Agreement and shall be valid unless terminated by
                  either Party in accordance with the terms of this Agreement.
                  <br />
                  <br />
                  Either party may terminate this Agreement on giving one (1)
                  day's notice in writing to the other Party.
                  <br />
                  <br />
                  The Service Provider confirms that Truckae does not own or in
                  any way control the vehicles used by the Service Provider to
                  provide the services to the Customer (Service Provider's
                  Customer, i.e. individuals or businesses seeking
                  transportation service providers on Truckae's technology
                  platform). Truckae shall not be held liable or responsible in
                  any manner whatsoever for any failures, errors or defects in
                  the provision of the Services provided by the Service Provider
                  to the Customer.
                  <br />
                  <br />
                  The Service Provider shall indemnify Truckae for all
                  direct/indirect liabilities, losses, charges and expenses
                  incurred by Truckae due to the negligence and/or any unlawful
                  act or omission of the Service Provider and/or the Service
                  Provider's appointed operators/contractors in the performance
                  of the Services.
                  <br />
                  <br />
                  Truckae shall not be responsible or liable for any loss or
                  damage, howsoever caused or suffered by the Service Provider
                  arising out of the use of the Service offered by Truckae or
                  due to the failure of Truckae to provide Services to the
                  consignor/consignee for any reason whatsoever including but
                  not limited to any Customer's non-compliance with the Services
                  offered by Truckae, which includes, but is not limited to any
                  incorrectly placed voice instructions, malfunction, partial or
                  total failure of any network terminal, data processing system,
                  computer tele transmission or telecommunication system or
                  other circumstances whether or not beyond the control of
                  Truckae or any person or organization involved in the above
                  mentioned systems.
                  <br />
                  <br />
                  Truckae shall not be liable for any direct or indirect loss or
                  damage, which may be suffered by the Service Provider as a
                  result of any failure by a consignor/consignee to meet the
                  timing notified to the Service Provider, this will include
                  circumstances where Truckae has agreed to such timing or if
                  the Customer has advised Truckae of the possibility that
                  he/she may not comply with the stipulated timing.
                  <br />
                  <br />
                  The Service Provider shall indemnify Truckae from and against
                  and in respect of any or all liabilities, losses, charges and
                  expenses (including legal fees and costs on a full indemnity
                  basis) claims, demands, actions and proceedings, which Truckae
                  may incur or sustain directly or indirectly from any breach by
                  Service Provider of its obligations hereunder or any breach of
                  Service Provider's representations and warranties, or by any
                  reason, or in relation to the provision of the Services by the
                  Service Provider, which shall include but not be limited to,
                  claims by Customers in respect of theft, damage or losses
                  pursuant to the Services. The Service Provider shall forthwith
                  pay all such sums forthwith on demand by Truckae in writing.
                  <br />
                  <br />
                  The Service Provider shall maintain all insurance required by
                  the laws of the United Arab Emirates in respect of the
                  provision of the Services. The Service Provider will also
                  maintain comprehensive insurance including without limitation
                  insurance of vehicles, drivers and Customer in respect of
                  accidents, damage, personal injury and theft. The customer
                  shall be excluded from any liability owed to the Service
                  Provider.
                  <br />
                  <br />
                  The Service Provider shall furnish all the details about its
                  drivers in attachment A for security reasons and ensure that
                  drivers are registered with Truckae.
                  <br />
                  <br />
                  Truckae's maximum liability to Service Provider in any event,
                  which includes any failure by Truckae under this Agreement,
                  shall be restricted and limited to a maximum amount of AED 50
                  (AED Fifty) in aggregate during the Term.
                  <br />
                  <br />
                  The Service Provider confirms that it has obtained driver
                  consent for the documents to be sent for background
                  verification and in the event of any discrepancy, this
                  Agreement stands null and void and the Services terminated
                  with immediate effect.
                  <br />
                  <br />
                  Service Provider hereby provides consent to Truckae to access,
                  use or share data and information, including but not limited
                  to, location information, contact information, transaction
                  information, usage and preference information, device
                  information, call and SMS data and Log information related to
                  subscription to Truckae's platform.
                </p>
              </div>
            </div>

            <div className={styles.privacypolicyh2divpart1div22}>
              <div className={styles.privacypolicyh2divpart1divp1}>
                <h3>RATES AND PAYMENT TERMS</h3>
                <p>
                  The applicable financial terms for the vehicle may be subject
                  to regular and frequent changes at the sole discretion of
                  Truckae, which shall be communicated
                  <br />
                  <br />
                  Trip fare will be calculated through the software system by
                  Truckae and in case of any fare dispute, the decision of
                  Truckae will be final and binding.
                  <br />
                  <br />
                  All payments due / receivable to / from to the Service
                  Provider shall be made / billed by cash, online modes of
                  payments or any other modes , as mutually agreed from time to
                  time.
                </p>
              </div>
            </div>

            <div className={styles.privacypolicyh2divpart1div22}>
              <div className={styles.privacypolicyh2divpart1divp1}>
                <h3>SERVICE PROVIDER'S OBLIGATIONS</h3>
                <p>
                  The Service Provider shall ensure that the Services are
                  provided in compliance with all laws applicable in the United
                  Arab Emirates and as applied in the Emirate of Dubai and in
                  strict compliance with the provisions of this Agreement and
                  its Attachments.
                  <br />
                  <br />
                  The Service Provider shall ensure that in providing the
                  Services the Service provider does not carry goods the
                  carriage of which is prohibited by the laws of the United Arab
                  Emirates.
                  <br />
                  <br />
                  The Service Provider shall provide the Services with the due
                  care, skill and diligence of a reasonable prudent operator
                  (“RPO”) and shall procure that any person performing under
                  this Agreement shall provide the Services to the best of
                  his/her ability and in accordance with the standards of an
                  RPO. For the purposes of this Agreement, a “RPO” means a
                  person seeking in good faith to perform its contractual
                  obligations and in so doing and in the general conduct of its
                  undertaking, exercises that degree of skill, diligence,
                  prudence and foresight which would reasonably and ordinarily
                  be expected from a skilled and experienced operator complying
                  with all applicable laws and statutory obligations, engaged in
                  same type of undertaking, under the same or similar
                  circumstances and conditions and any reference to the
                  standards of a RPO will be construed accordingly.
                  <br />
                  <br />
                  The Service Provider agrees to provide the Services in a
                  manner so as to advance the business interests and goodwill of
                  Truckae. Specifically, the Service Provider agrees not to, and
                  shall ensure that its drivers do not, (a) form any union,
                  trusts, association, society or any other type of entity; or
                  (b) hold meetings, conference or any other form of gathering
                  of five (5) or more, to discuss this Agreement, the Services
                  and payments provided hereunder and/or the general affairs of
                  Truckae.
                  <br />
                  <br />
                  The Service Provider shall not directly or indirectly engage
                  in any conduct averse to the best interests of Truckae. The
                  Service Provider shall not, and shall ensure that its drivers
                  and other employees do not, disparage or make any negative
                  comments (either oral or in writing) about, Truckae or any of
                  its officers, employees, directors or other representatives.
                  <br />
                  <br />
                  Breach of Clauses 19-21 shall entitle the Company to terminate
                  this Agreement with immediate effect.
                  <br />
                  <br />
                  The Service Provider will ensure that drivers have functioning
                  mobile numbers and also have the ability to read and send SMSs
                  from Truckae, regarding the customer details, Kms and time
                  reading, amount paid by the customer and to convey customer
                  feedback.
                  <br />
                  <br />
                  The goods left in the vehicle shall at all times be secure,
                  and shall not be pilfered or tampered with. In the event that
                  the goods are stolen or damaged the driver will immediately
                  notify Truckae.
                  <br />
                  <br />
                  The Driver must be aware of the prominent destinations and
                  routes, both outside and within Dubai.
                  <br />
                  <br />
                  Any promotional offer / discounts given to the Customer, will
                  be decided by Truckae in its sole consideration based on a
                  case to case basis and the Service Provider shall agree to the
                  same.
                  <br />
                  <br />
                  The Service Provider shall ensure that before the collection,
                  the driver's phone is available for the Customer and Truckae
                  to call. A driver's phone should not be busy for any long
                  periods, unavailable or switched off during an ongoing (live)
                  order or service. In such circumstances, the Truckae may
                  adjust the discounts given to the Customer and deduct such sum
                  from payments to the Service Provider. The deductions may
                  extend up to 140% of the sum to be paid to the Service
                  Provider.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
