import React, { useEffect, useState } from "react";
import secondbg from "../../../assets/secondbg.png";
import Aos from "aos";
import "aos/dist/aos.css";
import styles from "./DelveryMoving.module.css";
import cx from "classnames";
import DeliveryComp from "../../DeliveryComp/DeliveryComp";
import MovingComp from "../../MovingComp/MovingComp";

export default function DelveryMoving() {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);
  const [btnshow, setBtnshow] = useState("dl");

  const clickfn = (i) => {
    setBtnshow(i);
  };

  return (
    <div className={styles.bckdivmaindiv}>
      <div className={styles.bckdiv}>
        <img src={secondbg} alt="truckae" />
      </div>
      <div className={styles.dlrymvngtop}>
        <div className={styles.dlrymvng}>
          <div className={styles.dlrymvngtoph2}>
            <h2>Our Features</h2>
          </div>
          <div className={styles.dlrymvngggg}>
            <div className={styles.dlrymvngbtns}>
              <button
                className={cx(
                  `${styles.btn} ${btnshow === "dl" ? [styles.btnsh] : ""}`
                )}
                onClick={() => clickfn("dl")}
              >
                Delivery
              </button>
              <button
                className={cx(
                  `${styles.btn} ${btnshow === "mo" ? [styles.btnsh] : ""}`
                )}
                onClick={() => clickfn("mo")}
              >
                Moving
              </button>
            </div>
          </div>
          <div className={styles.dlrymvngbck}>
            {btnshow === "dl" ? (
              <DeliveryComp btnshow={btnshow} />
            ) : (
              <MovingComp btnshow={btnshow} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
